import React from "react"

const GoogleMap = ({ address, lat, lon }) => {
  // Map takes either an address OR lat and lon
  const location = address ? `${address}` : `${lat},${lon}`;
  const googleApiKey = process.env.GATSBY_GOOGLE_MAPS_KEY;
  return (
    <div className="sponsor__map">
      <iframe 
        title="Map"
        style={{ border: 0 }}
        width="100%"
        height="100%" 
        loading="lazy" 
        src={`https://www.google.com/maps/embed/v1/place?key=${googleApiKey}&q=${location.replace(/\s/g, '+')}`}>
      </iframe>
    </div>
  )
}
 
export default GoogleMap