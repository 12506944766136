import React from "react"

// helper funciton from https://gist.github.com/shabith/9801530
function extractVideoId(url) {
  const videoIdRegex = /^.*((youtu.be\/|vimeo.com\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#\&\?]*).*/;
  const match = url.match(videoIdRegex);
  let videoId;

  if (match && match[7]){
    videoId = match[7];
    return videoId;
  } else {
    //invalid
    console.warn("Invalid Video URL");
  }
}

const Video = ({ title, videoUrl }) => {
  const videoId = extractVideoId(videoUrl);
  return (
    <div className="video">
      <iframe
        title={title}
        src={`https://www.youtube.com/embed/${videoId}?color=white&modestbranding=1&showinfo=0`}
        frameBorder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      />
    </div>
  )
}

export default Video