import React from "react"
import { graphql } from "gatsby"
import { allRoutes } from "../helpers/routes"
import Img from "gatsby-image"
import Layout from "../components/layout"
import Video from "../components/video"
import LinkBtn from "../components/linkBtn"
import GoogleMap from "../components/map"
import { 
  SocialLink,
  SocialFacebook,
  SocialTwitter} from "../assets/images"

const LegacyPage = ({ data }) => {
  const legacySeniorCommunitiesAddress = "8240 Manderville Lane Dallas, Texas 75231";
  return (
    <Layout>
      <header className="sponsor__header sponsor__header--title">
        <div className="content-area sponsor__overview">
          <LinkBtn className="sponsor__logo" to="https://www.thelegacyseniorcommunities.org/">
            <Img fluid={data.legacySeniorCommunities.childImageSharp.fluid} alt="The Legacy Senior Communities logo" />
          </LinkBtn>
          <div>
            <h1>The Legacy Senior Communities</h1>
            <p className="sponsor__tagline">Tradition and Innovation in Senior Living</p>
          </div>
        </div>
      </header>
      <div className="content-area">
        <article className="sponsor">
          <div className="sponsor-content">
            <Video title="title" videoUrl="https://www.youtube.com/watch?v=1rWyp6Ky4NM" />
            <div className="rich-text">
              <p>The Legacy Senior Communities is a Jewish-sponsored, not-for-profit 501(c)(3) charitable organization, and the parent of The Legacy Willow Bend life care community in Plano, The Legacy at Home (a home health care agency), and The Legacy Midtown Park rental retirement community (coming 2019).</p>
              <p>Since 1953, our entire organization has been focused on driving innovation every day to better serve seniors and their families in Greater Dallas.</p>
            </div>
          </div>
          <div className="sponsor-sidebar">
            <h2>Contact Information</h2>
            <GoogleMap address={legacySeniorCommunitiesAddress} />
            <div className="sponsor__address">
              <span>8240 Manderville Lane</span>
              <span className="separator">{" "}•{" "}</span>
              <span>Dallas, Texas 75231</span>
            </div>
            <p className="sponsor__address-note">Both Legacy Willow Bend and Legacy Midtown Park provide services to residents on a 24/7 basis. Business is conducted based upon appointments only. During this time of Covid, the visitation policy for residents is based upon the guidelines set forth by the CDC, CMS, HHSC, and other local, state, and federal authorities. It is recommended to contact the community directly for the most updated information.</p>
            <hr />
            <span className="contact">
              <LinkBtn className="contact__email link--underline" to="mailto:dhanks@thelegacymp.org">
                dhanks@thelegacymp.org
              </LinkBtn>
            </span>
            <span className="separator">{" "}•{" "}</span>
            <a className="contact contact__phone" href="tel:12143796700">
              1-214-379-6700
            </a>
            <ul className="social">
              <li>
                <LinkBtn to="https://www.thelegacyseniorcommunities.org/" title="The Legacy Senior Communities Website">
                  <SocialLink />
                  <span className="is-visually-hidden">
                    https://www.thelegacyseniorcommunities.org/
                  </span>
                </LinkBtn>
              </li>
              <li>
                <LinkBtn to="https://www.facebook.com/Legacyscinfo/" title="Facebook">
                  <SocialFacebook />
                  <span className="is-visually-hidden">
                    https://www.facebook.com/Legacyscinfo/
                  </span>
                </LinkBtn>
              </li>
              <li>
                <LinkBtn to="https://twitter.com/legacyscinfo" title="Twitter">
                  <SocialTwitter />
                  <span className="is-visually-hidden">
                    https://twitter.com/legacyscinfo
                  </span>
                </LinkBtn>
              </li>
            </ul>
          </div>
        </article>
        <div className="cta-block-bottom">
          <LinkBtn className="btn" to={allRoutes.directory}>
            Back to the Directory
          </LinkBtn>
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query LegacyPageQuery {
    legacySeniorCommunities: file(relativePath: { eq: "legacy-logo-2023.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 260) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
  }
`

export default LegacyPage